/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "@angular/material/prebuilt-themes/indigo-pink.css";
// @import '@angular/material/theming'; I guess not needed anymore in Angular 17+?

// App root
app-root {
  height: 100vh;
  padding: 0px;
  margin: 0px;
}

// Home component selector
app-sbol-canvas {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

mat-sidenav-container {
  overflow: auto;
  flex-grow: 1;
}

// For some reason the body styling is adding margin...
body {
  margin: 0px;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

// Primarily used such that form fields are correctly sized
.dense-3 {
  @include mat.all-component-densities(-3);
}

.mat-tooltip {
  word-break: break-all !important;
  white-space: normal !important;
}

// Angular 15+ changed some styles, the below is needed to make it look nice again
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mdc-tab {
  min-width: 130px!important;
}

.mat-mdc-tab-body{
  padding: 20px;
}

.mat-mdc-form-field-subscript-wrapper{
  height: 5px;
}

.mat-expansion-panel{
  box-shadow: none !important;
}